import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import cfg from '@/config'
import UseTemplateDialog from '../../components/dialogs/UseTemplateDialog.vue'
import RemoveSelectorTemplate from '../TemplateEditor/RemoveSelectorTemplate.vue'

export default {
  components: { UseTemplateDialog },
  props: {
    template: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      dialog: false,
      cfg
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    }),
    disabledRule () {
      let rule
      if (this.account && this.account.companyId) rule = false
      else rule = true
      return rule
    }
  },
  methods: {
    ...mapActions({
      // fetchTemplates: 'templates/fetchTemplates',
      // addTemplate: 'templates/addTemplate',
      updateTemplate: 'templates/updateTemplate',
      updateCompanyTemplate: 'companyTemplates/updateCompanyTemplate',
      updateGroupTemplate: 'groupTemplates/updateGroupTemplate',
      deleteTemplate: 'templates/deleteTemplate',
      deleteCompanyTemplate: 'companyTemplates/deleteCompanyTemplate',
      deleteGroupTemplate: 'groupTemplates/deleteGroupTemplate',
      addToast: 'toasts/addToast'
    }),
    getTemplateSharedWithLength (template) {
      return template.sharedWith.accounts.length + template.sharedWith.company.length + template.sharedWith.groups.length
    },
    closeDialog () {
      this.useTemplateDialog = false
    },
    openOverview () {
      EventBus.$emit('drawer-overview-template', this.template._id)
    },
    viewTemplate (template, type) {
      if (type && type === 'view') { this.$router.push({ path: `/templates/${template._id}?view=true` }) } else this.$router.push({ path: `/templates/${template._id}` })
    },
    previewTemplate (template) {
      this.$emit('lavvira-template-preview', template)
    },
    shareTemplate (template, actualTab) {
      EventBus.$emit('dialog-share-template', template, actualTab)
    },
    translateCategoryIfNeeded (category) {
      if (category === 'none') return this.$t('common|not_available')
      else return category
    },
    async shareLavviraTemplate (template) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('common|are_you_sure') + ' ' + this.$t('warning|template_available_for_all_users')
      })
      if (res) {
        const _id = template._id
        const payload = {
          lavviraTemplate: true,
          shared: true
        }
        try {
          await this.updateTemplate({ _id, payload })
        } catch (error) {
          console.error(error, 'error')
        }
      }
    },
    async unShareLavviraTemplate (template) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('common|are_you_sure') + ' ' + this.$t('warning|template_no_longer_available_for_all_users')
      })
      if (res) {
        const _id = template._id
        const payload = {
          unShareLavviraTemplate: true,
          shared: false
        }
        try {
          await this.updateTemplate({ _id, payload })
        } catch (error) {
          console.error(error, 'error')
        }
      }
    },
    async pinToDashboard (template) {
      if (this.isMyTab || this.isLavviraTab) {
        const payload = {
          setPin: this.account._id
        }
        try {
          await this.updateTemplate({
            _id: template._id,
            payload
          })
          this.addToast({
            title: this.$t('message|template_pin_dashboard_success'),
            color: 'white',
            snackbarColor: 'success'
          })
        } catch (e) {
          this.addToast({
            title: this.$t('error|template_pin_dashboard_fail'),
            color: 'white',
            snackbarColor: 'error'
          })
          console.error(e, 'error')
        }
      }
      if (this.isCompanyTab) {
        const payload = {
          setPin: this.account._id
        }
        try {
          await this.updateCompanyTemplate({
            _id: template._id,
            payload
          })
          this.addToast({
            title: this.$t('message|template_pin_dashboard_success'),
            color: 'white',
            snackbarColor: 'success'
          })
        } catch (e) {
          this.addToast({
            title: this.$t('error|template_pin_dashboard_fail'),
            color: 'white',
            snackbarColor: 'error'
          })
          console.error(e, 'error')
        }
      }
      if (this.isGroupTab) {
        const payload = {
          setPin: this.account._id
        }
        try {
          await this.updateGroupTemplate({
            _id: template._id,
            payload
          })
          this.addToast({
            title: this.$t('message|template_pin_dashboard_success'),
            color: 'white',
            snackbarColor: 'success'
          })
        } catch (e) {
          this.addToast({
            title: this.$t('error|template_pin_dashboard_fail'),
            color: 'white',
            snackbarColor: 'error'
          })
          console.error(e, 'error')
        }
      }
      // if (this.isLavviraTab) {
      //   const payload = {
      //     setPin: this.account._id
      //   }
      //   try {
      //     await this.updateTemplate({
      //       _id: template._id,
      //       payload
      //     })
      //     this.addToast({
      //       title: 'Template pinned to dashboard',
      //       color: 'white',
      //     })
      //   } catch (e) {
      //     this.addToast({
      //       title: 'Template pinned to dashboard failed',
      //       color: 'error'
      //     })
      //     console.error(e, 'error')
      //   }
      // }
    },
    async removePinFromDashboard (template, location) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('actions|remove_resource_from_pinboard')
      })
      if (!res) return
      if (res) {
        if (this.isMyTab || this.isLavviraTab) {
          const payload = {
            removePin: this.account._id
          }
          try {
            await this.updateTemplate({
              _id: template._id,
              payload
            })
            this.addToast({
              title: this.$t('message|template_remove_dashboard_success'),
              color: 'white',
              snackbarColor: 'success'
            })
          } catch (e) {
            this.addToast({
              title: this.$t('error|template_remove_dashboard_fail'),
              color: 'white',
              snackbarColor: 'error'
            })
            console.error(e, 'error')
          }
        }
        if (this.isCompanyTab) {
          const payload = {
            removePin: this.account._id
          }
          try {
            await this.updateCompanyTemplate({
              _id: template._id,
              payload
            })
            this.addToast({
              title: this.$t('message|template_remove_dashboard_success'),
              color: 'white',
              snackbarColor: 'success'
            })
          } catch (e) {
            this.addToast({
              title: this.$t('error|template_remove_dashboard_fail'),
              color: 'white',
              snackbarColor: 'error'
            })
            console.error(e, 'error')
          }
        }
        if (this.isGroupTab) {
          const payload = {
            removePin: this.account._id
          }
          try {
            await this.updateGroupTemplate({
              _id: template._id,
              payload
            })
            this.addToast({
              title: this.$t('message|template_remove_dashboard_success'),
              color: 'white',
              snackbarColor: 'success'
            })
          } catch (e) {
            this.addToast({
              title: this.$t('error|template_remove_dashboard_fail'),
              color: 'white',
              snackbarColor: 'error'
            })
            console.error(e, 'error')
          }
        }
        if (location && location === 'dashboard') {
          const payload = {
            removePin: this.account._id
          }
          try {
            await this.updateTemplate({
              _id: template._id,
              payload
            })
            this.addToast({
              title: this.$t('message|template_remove_dashboard_success'),
              color: 'white',
              snackbarColor: 'success'
            })
          } catch (e) {
            this.addToast({
              title: this.$t('error|template_remove_dashboard_fail'),
              color: 'white',
              snackbarColor: 'error'
            })
            console.error(e, 'error')
          }
        }
      }
    },
    async deleteActionTemplateCardMini (template) {
      const updateTemplate = this.updateTemplate
      const account = this.account
      const company = this.company
      const remove = this.$t('actions|remove')
      const from = this.$t('common|from')
      const select = this.$t('actions|select')
      const required = this.$t('fields|required')
      const myTemplates = this.$t('templates|my_templates')
      RemoveSelectorTemplate.props = {
        templateProp: {
          type: Object,
          default: () => ({ template })
        },
        accountProp: {
          type: Object,
          default: () => ({ account })
        },
        companyProp: {
          type: Object,
          default: () => ({ company })
        },
        updateTemplateFunction: {
          type: Function,
          default: updateTemplate
        },
        $t_remove: {
          type: String,
          default: remove
        },
        $t_from: {
          type: String,
          default: from
        },
        $t_select: {
          type: String,
          default: select
        },
        $t_required: {
          type: String,
          default: required
        },
        $t_myTemplates: {
          type: String,
          default: myTemplates
        },
        onClick: Function
      }
      const dialogInstance = await this.$dialog.show(RemoveSelectorTemplate, { onClick: this.closeDialogInstance.bind(this.closeDialogInstance) })
      this.dialogInstance = dialogInstance
    },
    closeDialogInstance () {
      this.dialogInstance.close()
    },
    async deleteTemplateAction (template) {
      let removeAction
      let deleteAction
      if (template.sharedWith.accounts.length + template.sharedWith.company.length + template.sharedWith.groups.length > 1) {
        removeAction = true
        deleteAction = false
      } else {
        removeAction = false
        deleteAction = true
      }
      if (removeAction) {
        const res = await this.$dialog.confirm({
          text: `${this.$t('warning|remove_this_template_from') }
          ${this.isMyTab ? this.$t('templates|my_templates') : this.isCompanyTab ? this.company.companyData.companyName : this.isGroupTab ? this.group.groupName : ''}
          ${this.$t('templates|templates').toLowerCase()}?`,
          title: this.$t('common|warning')
        })
        if (!res) return
        if (this.isMyTab) {
          const payload = {
            removeFromShared: this.account._id,
            account: true
          }
          try {
            await this.updateTemplate({
              _id: template._id,
              payload
            })
            this.addToast({
              title: this.$t('message|template_removal_success'),
              color: 'white',
              snackbarColor: 'success'
            })
          } catch (e) {
            this.addToast({
              title: this.$t('error|template_removal_fail'),
              color: 'white',
              snackbarColor: 'error'
            })
          }
        }
        if (this.isCompanyTab) {
          const payload = {
            removeFromShared: this.company._id,
            company: true
          }
          try {
            await this.updateCompanyTemplate({
              _id: template._id,
              payload
            })
            this.addToast({
              title: this.$t('message|template_removal_success'),
              color: 'white',
              snackbarColor: 'success'
            })
          } catch (e) {
            this.addToast({
              title: this.$t('error|template_removal_fail'),
              color: 'white',
              snackbarColor: 'error'
            })
          }
        }
        if (this.isGroupTab) {
          const payload = {
            removeFromShared: this.group._id,
            group: true
          }
          try {
            await this.updateGroupTemplate({
              _id: template._id,
              payload
            })
            this.addToast({
              title: this.$t('message|template_removal_success'),
              color: 'white',
              snackbarColor: 'success'
            })
          } catch (e) {
            this.addToast({
              title: this.$t('error|template_removal_fail'),
              color: 'white',
              snackbarColor: 'error'
            })
          }
        }
      }
      if (deleteAction) {
        const res = await this.$dialog.confirm({
          title: this.$t('common|warning'),
          text: this.$t('warning|delete_template')
        })
        if (!res) return
        if (this.isMyTab) {
          try {
            await this.deleteTemplate(template)
            this.addToast({
              title: this.$t('message|template_delete_success'),
              color: 'white',
              snackbarColor: 'success'
            })
          } catch (e) {
            this.addToast({
              title: this.$t('message|template_delete_fail'),
              color: 'white',
              snackbarColor: 'error'
            })
          }
        }
        if (this.isCompanyTab) {
          try {
            await this.deleteCompanyTemplate(template)
            this.addToast({
              title: this.$t('message|template_delete_success'),
              color: 'white',
              snackbarColor: 'success'
            })
          } catch (e) {
            this.addToast({
              title: this.$t('message|template_delete_fail'),
              color: 'white',
              snackbarColor: 'error'
            })
          }
        }
        if (this.isGroupTab) {
          try {
            await this.deleteGroupTemplate(template)
            this.addToast({
              title: this.$t('message|template_delete_success'),
              color: 'white',
              snackbarColor: 'success'
            })
          } catch (e) {
            this.addToast({
              title: this.$t('message|template_delete_fail'),
              color: 'white',
              snackbarColor: 'error'
            })
          }
        }
      }
    }
  }
}
