<template>
  <v-card>
    <v-card-title>
      <span>{{ $t('actions|use_template') }} {{ template.name }}</span>
      <v-spacer />
      <v-btn
        icon
        class="mt-n2"
        @click="$emit('closeDialog'); reset()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="py-4">
      <v-stepper
        v-model="e6"
        vertical
        flat
      >
        <v-stepper-step
          :complete="e6 > 1"
          step="1"
        >
          {{ $t('actions|add_to') }}
          <small
            v-if="selectedSection"
            class="mt-1"
          >
            {{ selectedSection }}
          </small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card
            flat
            class="mb-12"
            height="30px"
          >
            <v-select
              v-model="selectedSection"
              :items="availableSections"
              item-text="text"
              item-value="text"
              item-disabled="disabled"
              :label="$t('templates|template_is_provided_for')"
              dense
              outlined
              class="mt-6"
              @input="e6++"
            />
          </v-card>
        </v-stepper-content>

        <v-stepper-step
          :complete="e6 > 2"
          step="2"
        >
          Choose type
          <small
            v-if="selection"
            class="mt-1"
          >
            {{ selection }}
          </small>
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card
            flat
            class="mb-12"
            height="40px"
          >
            <v-select
              v-model="selection"
              :items="itemList"
              :label="$t('actions|select')"
              :rules="[rules.required]"
              item-text="text"
              item-value="value"
              dense
              outlined
              class="mt-6"
              @input="e6++"
            />
          </v-card>
          <v-btn
            text
            rounded
            @click="e6--; selection = null"
          >
            <v-icon
              class="mr-1"
            >
              mdi-undo
            </v-icon>
            {{ $t('actions|back') }}
          </v-btn>
          <v-spacer />
        </v-stepper-content>

        <v-stepper-step
          :complete="e6 > 3"
          step="3"
        >
          Select an ad format and name ad unit
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-card
            flat
            class="mb-12 pt-4"
            height="100px"
          >
            <v-form
              ref="form"
            >
              <v-select
                v-if="selection === 'Cases'"
                v-model="selectedCaseId"
                :items="caseList"
                :label="$t('actions|choose_case')"
                outlined
                :rules="[rules.required]"
                dense
                item-text="caseName"
                item-value="_id"
              />
              <v-select
                v-if="selectedCaseId.length && selection === 'Cases'"
                v-model="selectedWorkbench"
                :items="workbenchList.workbenches"
                :label="$t('actions|choose_workbench')"
                outlined
                :rules="[rules.required]"
                dense
                item-text="name"
                item-value="_id"
              />
              <v-select
                v-if="selection === 'Case Models'"
                v-model="selectedCaseModelId"
                :items="caseModelList"
                :label="$t('actions|choose_case_model')"
                outlined
                :rules="[rules.required]"
                dense
                item-text="name"
                item-value="_id"
              />
              <v-select
                v-if="selectedCaseModelId.length && selection === 'Case Models'"
                v-model="selectedWorkbenchCaseModel"
                :items="workbenchCaseFormList.workbenches"
                :label="$t('actions|choose_workbench')"
                outlined
                :rules="[rules.required]"
                dense
                item-text="name"
                item-value="_id"
              />
            </v-form>
          </v-card>
          <v-btn
            text
            rounded
            @click="e6--;"
          >
            <v-icon
              class="mr-1"
            >
              mdi-undo
            </v-icon>
            {{ $t('actions|back') }}
          </v-btn>
        </v-stepper-content>
      </v-stepper>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        rounded
        dense
        small
        min-width="150"
        :loading="loading"
        :disabled="disabledRule()"
        class="mb-6"
        @click="useTemplate"
      >
        {{ $t('actions|submit') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { userHasAccess } from '@/utils/utils'
import { mapState, mapActions } from 'vuex'
import generalMixin from '@/utils/generalMixin.js'

export default {
  mixins: [generalMixin],
  props: {
    template: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      e6: 1,
      dialog: false,
      selection: null,
      selectedSection: null,
      selectedCaseId: '',
      selectedCaseModelId: '',
      selectedWorkbench: null,
      selectedWorkbenchCaseModel: null,
      loading: false,
      rules: {
        required: value => !!value || this.$t('common|field_required')
      }
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      caseForms: state => state.caseForms.caseForms,
      companyCaseForms: state => state.companyCaseForms.companyCaseForms,
      groupCaseForms: state => state.groupCaseForms.groupCaseForms,
      cases: state => state.cases.cases,
      companyCases: state => state.companyCases.companyCases,
      groupCases: state => state.groupCases.groupCases
    }),
    itemList () {
      let items
      if (this.account.accountType === 'god') {
        items = [
          {
            text: this.$t('case_models|case_models'),
            value: 'Case Models'
          }
        ]
      } else {
        items = [
          {
            text: this.$t('cases|cases'),
            value: 'Cases'
          },
          {
            text: this.$t('case_models|case_models'),
            value: 'Case Models'
          }
        ]
      }
      return items
    },
    caseList () {
      let group = null
      if (this.company) group = this.company.groups.find(g => g.groupName.indexOf(this.selectedSection) !== -1)
      if (
        (this.selectedSection &&
        this.selectedSection.includes(this.company.companyData.companyName)
        )
      ) {
        return this.companyCases
      } else if (this.selectedSection && group) {
        let grCases = []
        this.groupCases.forEach(_case => {
          _case.sharedWith.groups.forEach(gr => {
            if (gr._id === group._id) grCases.push(_case)
          })
        })
        return grCases
      } else {
        return this.cases.map((c) => {
          const data = { ...c }
          return data
        })
      }
    },
    workbenchList () {
      let foundCase = this.cases.find(c => c._id === this.selectedCaseId) ||
      this.companyCases.find(c => c._id === this.selectedCaseId) ||
      this.groupCases.find(c => c._id === this.selectedCaseId)
      return foundCase
    },
    caseModelList () {
      let group = null
      if (this.company) group = this.company.groups.find(g => g.groupName.indexOf(this.selectedSection) !== -1)
      if (
        (this.selectedSection &&
        this.selectedSection.includes(this.company.companyData.companyName)
        )
      ) {
        return this.companyCaseForms
      } else if (this.selectedSection && group) {
        let grCases = []
        this.groupCaseForms.forEach(_caseForm => {
          _caseForm.sharedWith.groups.forEach(gr => {
            if (gr._id === group._id) grCases.push(_caseForm)
          })
        })
        return grCases
      } else {
        return this.caseForms.map((c) => {
          const data = { ...c }
          return data
        })
      }
    },
    workbenchCaseFormList () {
      let foundCaseForm = this.caseForms.find(cf => cf._id === this.selectedCaseModelId) ||
      this.companyCaseForms.find(cf => cf._id === this.selectedCaseModelId) ||
      this.groupCaseForms.find(cf => cf._id === this.selectedCaseModelId)
      return foundCaseForm
    },
    availableSections () {
      let myAvailableSections = []

      const getAccess = (_acc, _comp, _group, _tab, _from) => {
        return userHasAccess(_acc, _comp, _group, _tab, _from)
      }
      const accountAccess = getAccess(this.account, null, null, null, 'onlyAccountAccess')
      const companyAccess = getAccess(this.account, this.company, null, null, 'availableSections')

      if (this.account && !this.account.companyId) {
        myAvailableSections.push({
          text: this.$t('common|my') + ' ' + this.$t('cases|cases') + ' / ' + this.$t('case_models|case_models'),
          disabled: !accountAccess
        })
      }
      if (this.account && this.account.companyId && this.company) {
        const userRole = this.verifyUserRole(this.company, this.account)
        // verifyUserRole could be found in generalMixin
        if (userRole === 'regular') {
          myAvailableSections.push({
            text: this.company.companyData.companyName,
            disabled: !companyAccess
          })
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const participation = group.groupMembers.find(m => m._id === this.account._id)
            if (participation) {
              myAvailableSections.push({
                text: group.groupName,
                disabled: !companyAccess
              })
            }
          }
        } else {
          myAvailableSections.push({
            text: this.$t('common|my') + ' ' + this.$t('cases|cases') + ' / ' + this.$t('case_models|case_models'),
            disabled: !accountAccess
          })
          myAvailableSections.push({
            text: this.company.companyData.companyName,
            disabled: !companyAccess
          })
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const participation = group.groupMembers.find(m => m._id === this.account._id)
            if (participation) {
              myAvailableSections.push({
                text: group.groupName,
                disabled: !companyAccess
              })
            }
          }
        }
      }
      return myAvailableSections
    }
  },
  watch: {
    selection (value) {
      if (value === 'Cases') {
        this.resetCaseModelSelection()
      } else if (value === 'Case Models') {
        this.resetCaseSelection()
      }
    },
    dialog (value) {
      if (!value) this.reset()
    }
  },
  methods: {
    ...mapActions({
      addCaseWorkbenchTemplate: 'cases/addCaseWorkbenchTemplate',
      addCaseFormWorkbenchTemplate: 'caseForms/addCaseFormWorkbenchTemplate'
    }),
    openDialog () {
      this.dialog = true
      return this.dialog
    },
    reset () {
      this.e6 = 1
      this.selection = null
      this.selectedCaseModelId = ''
      this.selectedWorkbenchCaseModel = ''
      this.selectedCaseId = ''
      this.selectedWorkbench = ''
      this.$refs.form.resetValidation()
    },
    resetCaseModelSelection () {
      this.selectedCaseModelId = ''
      this.selectedWorkbenchCaseModel = ''
    },
    resetCaseSelection () {
      this.selectedCaseId = ''
      this.selectedWorkbench = ''
    },
    disabledRule () {
      if (this.selectedWorkbench || this.selectedWorkbenchCaseModel) {
        return false
      } else return true
    },
    async useTemplate () {
      this.loading = true
      if (this.selectedCaseId && this.selectedWorkbench) {
        await this.addCaseWorkbenchTemplate({
          _id: this.selectedCaseId,
          workbenchId: this.selectedWorkbench,
          payload: {
            templateRef: this.template._id,
            name: this.template.name
          }
        })
      }
      if (this.selectedCaseModelId && this.selectedWorkbenchCaseModel) {
        await this.addCaseFormWorkbenchTemplate({
          _id: this.selectedCaseModelId,
          workbenchId: this.selectedWorkbenchCaseModel,
          payload: {
            templateRef: this.template._id,
            name: this.template.name
          }
        })
      }
      this.loading = false
      this.resetCaseModelSelection()
      this.resetCaseSelection()
      this.$emit('closeDialog')
    }
  }
}
</script>
