<template>
  <v-card
    height="200"
    class="pa-2"
  >
    <v-card-title>
      {{ $t_remove }} {{ templateProp.name }} {{ $t_from }}:
    </v-card-title>
    <v-select
      v-model="selectedItem"
      :items="items"
      outlined
      :label="$t_select"
      dense
      required
      :rules="[(v) => !!v || $t_required]"
      :clearable="true"
      class="mt-2 mx-2"
    />
    <v-card-actions>
      <v-spacer />
      <v-btn
        small
        rounded
        color="primary"
        :disabled="!selectedItem"
        min-width="100"
        @click="removeCaseFrom(selectedItem); onClick()"
      >
        {{ $t_remove }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  data () {
    return {
      selectedItem: ''
    }
  },
  computed: {
    items () {
      let itemsArray = []
      const _template = this.templateProp.template
      const account = this.accountProp.account
      if (_template && _template.sharedWith.accounts.length) {
        const index = _template.sharedWith.accounts.findIndex(acc => acc._id === account._id)
        if (index !== -1) {
          itemsArray.push(this.$t_myTemplates)
        }
      }
      if (_template && _template.sharedWith.company.length) {
        itemsArray.push(_template.sharedWith.company[0].companyData.companyName)
      }
      if (_template && _template.sharedWith.groups.length) {
        _template.sharedWith.groups.forEach(group => {
          const participationIndex = group.groupMembers.findIndex(memberId => memberId === account._id)
          if (participationIndex !== -1) {
            itemsArray.push(group.groupName)
          }
        })
      }
      return itemsArray
    }
  },
  methods: {
    async removeCaseFrom (selectedItem) {
      const _template = this.templateProp.template
      const company = this.companyProp.company
      const account = this.accountProp.account
      const updateTemplate = this.updateTemplateFunction
      let payload
      company.groups.forEach(group => {
        if (group.groupName === selectedItem) {
          payload = {
            removeFromShared: group._id,
            group: true
          }
        }
      })
      if (selectedItem === company.companyData.companyName) {
        payload = {
          removeFromShared: company._id,
          company: true
        }
      }
      if (selectedItem === this.$t_myTemplates) {
        payload = {
          account: true,
          removeFromShared: account._id
        }
      }
      try {
        await updateTemplate({
          _id: _template._id,
          payload
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
